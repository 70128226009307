import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { ProcessLine } from 'modules/process-section/components/process-line';
import { arrayOf, shape, string } from 'prop-types';

const STextWrapper = styled.div`
    text-align: center;
`;

const SParagraph = styled(Paragraph)`
    margin-top: 1.5rem;
`;

export const ProcessSection = ({ title, description, data }) => {
    return (
        <Container>
            <STextWrapper>
                <HeaderSecond>
                    <FormattedMessage id={title} />
                </HeaderSecond>
                <SParagraph>
                    <FormattedMessage id={description} />
                </SParagraph>
            </STextWrapper>
            <ProcessLine data={data} />
        </Container>
    );
};

ProcessSection.propTypes = {
    title: string.isRequired,
    description: string.isRequired,
    headerWidth: string,
    data: arrayOf(
        shape({
            title: string,
            desc: string,
        }),
    ).isRequired,
};
