import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { string } from 'prop-types';

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
`;

const SHeaderThird = styled(HeaderThird)`
    margin: 0 auto;

    ${({ headerWidth }) =>
        css`
            max-width: ${headerWidth};
        `}
`;

const SParagraph = styled.p`
    max-width: 13.75rem;
    margin: 0 auto;
`;

export const ProcessDescription = ({ title, description, headerWidth }) => {
    return (
        <SContainer>
            <SHeaderThird headerWidth={headerWidth}>
                <FormattedMessage id={title} />
            </SHeaderThird>
            {description && (
                <SParagraph>
                    <FormattedMessage id={description} />
                </SParagraph>
            )}
        </SContainer>
    );
};

ProcessDescription.propTypes = {
    title: string.isRequired,
    description: string,
    headerWidth: string,
};

ProcessDescription.defaultProps = {
    description: '',
    headerWidth: '10.75rem',
};
