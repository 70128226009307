import React from 'react';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { ProcessDescription } from 'modules/process-section/components/process-description';
import { arrayOf, shape, string } from 'prop-types';

const SWrapper = styled.ul`
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;

    ${CONSTANTS.MEDIA.max1024`
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        gap: 4.75rem 1rem;
    `}
    ${CONSTANTS.MEDIA.max768`
        grid-template-columns: 1fr;
    `}
`;

const SItem = styled.li`
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;

    ${CONSTANTS.MEDIA.max1024`
        flex: unset;
    `}

    &:before {
        content: '';
        position: relative;
        z-index: 1;
        display: block;
        width: 0.3rem;
        height: 0.3rem;
        border: 0.125rem solid var(--primary-color);
        border-radius: 50%;
        background-color: var(--white-color);
        margin: 1rem auto 1.75rem;

        ${CONSTANTS.MEDIA.max1024`
            display: none;
        `}
    }

    &:last-child:before {
        top: 0.125rem;
    }

    &:not(:last-child):after {
        content: '';
        position: relative;
        top: 1.35rem;
        left: 50%;
        height: 0.125rem;
        background-color: var(--primary-color);
        order: -1;

        ${CONSTANTS.MEDIA.max1024`
            display: none;
        `}
    }
`;

const SParagraph = styled.p`
    font-size: 1.375rem;
    font-weight: 700;
    color: var(--gray-color);
    order: -1;
`;

export const ProcessLine = ({ data, headerWidth }) => {
    const processesToRender = data.map((process, index) => (
        <SItem key={index}>
            <SParagraph>{`0${index + 1}`}</SParagraph>
            <ProcessDescription
                title={process.title}
                description={process.desc}
                headerWidth={headerWidth}
            />
        </SItem>
    ));
    return <SWrapper>{processesToRender}</SWrapper>;
};

ProcessLine.propTypes = {
    headerWidth: string,
    data: arrayOf(
        shape({
            title: string,
            desc: string,
        }),
    ).isRequired,
};
