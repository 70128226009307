import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { array } from 'prop-types';

const SButton = styled.button`
    font-size: inherit;
    cursor: pointer;
    display: block;
`;

const openedUlStyles = css`
    height: 100%;
    opacity: 1;
    gap: 0.5rem;
    margin: 1rem 0 0.5rem 1rem;

    li span {
        height: 100%;
    }
`;

const SUl = styled.ul`
    display: flex;
    flex-direction: column;
    cursor: default;
    height: 0;
    opacity: 0;
    transition: ${({ isOpen }) => (isOpen ? 'opacity 0.3s ease-out' : 'unset')};

    li span {
        display: flex;
        height: 0;
    }

    ${({ isOpen }) => isOpen && openedUlStyles};
`;

export const ExpandableContent = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <SButton onClick={() => setIsOpen((prev) => !prev)}>
                <FormattedMessage
                    id={isOpen ? 'general.hide' : 'general.seeMore'}
                />
            </SButton>
            <SUl isOpen={isOpen}>
                {data.map((item, index) => (
                    <li key={index}>
                        <FormattedMessage id={item} />
                    </li>
                ))}
            </SUl>
        </>
    );
};

ExpandableContent.propTypes = {
    data: array.isRequired,
};
