export const MOBILE_DEVELOPMENT_PROCESS = [
    {
        title: 'view-mobile-projects-process-section.idea.title',
        desc: 'view-mobile-projects-process-section.idea.desc',
    },
    {
        title: 'view-mobile-projects-process-section.prototype.title',
        desc: 'view-mobile-projects-process-section.prototype.desc',
    },
    {
        title: 'view-mobile-projects-process-section.design.title',
        desc: 'view-mobile-projects-process-section.design.desc',
    },
    {
        title: 'view-mobile-projects-process-section.development.title',
        desc: 'view-mobile-projects-process-section.development.desc',
    },
    {
        title: 'view-mobile-projects-process-section.testing.title',
        desc: 'view-mobile-projects-process-section.testing.desc',
    },
];
