import React from 'react';
import { MobileDevelopmentView } from 'views/mobile-development';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import messages from 'constants/translations/services/mobile-development.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const MobileDevelopmentPage = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout
            location={location}
            messages={{ ...messages, ...caseStudiesMessages }}
        >
            <MobileDevelopmentView {...{ faqData }} />
        </Layout>
    );
};

MobileDevelopmentPage.propTypes = {
    location: object.isRequired,
};

export default MobileDevelopmentPage;

export const Head = () => <SEO tags={TAGS.MOBILE_DEVELOPMENT} />;

export const pageQuery = graphql`
    query MobileDevelopmentPageQuery {
        allContentfulFaq(
            filter: { slug: { eq: "services/mobile-development" } }
        ) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
