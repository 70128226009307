export const BLOG_POST_TAGS = {
    EB_HR_RESOURCES: 'ebHrResources',
    FINTECH: 'fintech',
    INDUSTRY_LEADERS: 'industryLeaders',
    TOP_GROWING_LEADERS: 'topGrowingLeaders',
    SOFTWARE_DEVELOPMENT_BUSINESS: 'softwareDevelopmentBusiness',
    BLOCKCHAIN_WEB3: 'blockchainWeb3',
    PROPTECH: 'proptech',
    PRESS_RELEASE: 'pressRelease',
    FRONTEND: 'frontend',
    REACT_JS: 'reactJs',
    BACKEND: 'backend',
    NODE_JS: 'nodeJs',
    NFT_REALITY: 'nftReality',
    SOFTWARE_ARCHITECTURE: 'softwareArchitecture',
    DATA_SCIENCE_ML: 'dataScienceMl',
    VUE_JS: 'vueJs',
    MOBILE: 'mobile',
    REACT_NATIVE: 'reactNative',
    BUSINESS_INTELLIGENCE: 'businessIntelligence',
    GEN_AI: 'genAi',
    OTHER: 'other',
    ENTERTAINMENT_STREAMING: 'entertainmentStreaming',
    UX_UI: 'uxUi',
    CLOUD_DEVOPS: 'cloudDevops',
    QA_SOFTWARE_TESTING: 'qaSoftwareTesting',
    FLOW_BLOCKCHAIN: 'flow-blockchain',
};
