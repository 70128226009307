import React from 'react';
import { GENRE_KEY } from 'constants/genre-key';
import { FAQSection, FaqSectionProps } from 'modules/faq-section';
import {
    LatestRelatedArticlesSection,
    LatestRelatedArticlesSectionProps,
} from 'modules/footer-section/components/latest-related-articles-section';
import { RequestSection } from 'modules/request-section';

const Props = {
    faqSection: FaqSectionProps,
    latestRelatedArticles: LatestRelatedArticlesSectionProps,
};

export const FooterSection = ({ faqSection, latestRelatedArticles }) => {
    const withFaq = faqSection.data.length > 0 && faqSection.title;

    const latestRelatedArticlesProperties = {
        ...latestRelatedArticles,
        genreKey: latestRelatedArticles?.genreKey || GENRE_KEY.GENRE,
    };

    return (
        <>
            {withFaq && <FAQSection {...faqSection} />}
            <LatestRelatedArticlesSection
                {...latestRelatedArticlesProperties}
            />
            <RequestSection />
        </>
    );
};

FooterSection.propTypes = Props;
FooterSection.defaultProps = {
    faqTitle: '',
    faqData: [],
};
