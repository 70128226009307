import React from 'react';
import { MOBILE_DEVELOPMENT_PROCESS } from 'views/mobile-development/process-section/constants';
import { ProcessSection } from 'modules/process-section';

export const MobileDevelopmentProcessSection = () => (
    <ProcessSection
        title="view-mobile-projects-process-section.title"
        description="view-mobile-projects-process-section.desc"
        data={MOBILE_DEVELOPMENT_PROCESS}
    />
);
