import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'components/container';
import { graphql, useStaticQuery } from 'gatsby';
import { RowWithTextImg } from 'modules/row-text-img';

export const PartnershipForYearsSection = () => {
    const { formatMessage } = useIntl();
    const images = useStaticQuery(graphql`
        {
            background: file(
                relativePath: {
                    eq: "mobile-development/partnership-for-years.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <Container>
            <RowWithTextImg
                title="view-mobile-projects-partnership-section.title"
                descriptions={[
                    'view-mobile-projects-partnership-section.desc.0',
                ]}
                img={images.background}
                alt={formatMessage({
                    id: 'view-mobile-projects-partnership-section.alt',
                })}
                isReversed
            />
        </Container>
    );
};
