import { graphql, useStaticQuery } from 'gatsby';

const BLOG_POSTS_QUERY = graphql`
    query BlogPostQuery {
        allContentfulBlogPost(
            filter: {
                node_locale: { eq: "en-US" }
                metadata: {
                    tags: {
                        elemMatch: {
                            contentful_id: { ne: "pressRelease" }
                            name: { ne: "nft-reality" }
                        }
                    }
                }
            }
            sort: { date: DESC }
        ) {
            edges {
                node {
                    id
                    title
                    genre
                    genreTechnology
                    genreExpertise
                    shortDescription {
                        internal {
                            content
                        }
                    }
                    metadata {
                        tags {
                            name
                            contentful_id
                        }
                    }
                    createdAt
                    updatedAt
                    date
                    image {
                        gatsbyImageData(width: 772, height: 541, quality: 100)
                    }
                    altThumbnailDescription
                    slug
                }
            }
        }
    }
`;

export const useFilteredBlogPosts = ({ tags = [], genre, genreKey } = {}) => {
    const data = useStaticQuery(BLOG_POSTS_QUERY);

    const filteredData = data.allContentfulBlogPost.edges.filter(({ node }) => {
        let matchesTags = true;
        let matchesGenre = true;

        if (tags.length > 0) {
            matchesTags = node.metadata.tags.some((tag) =>
                tags.includes(tag.contentful_id),
            );
        }

        if (genre && genreKey) {
            matchesGenre = node[genreKey] === genre;
        }

        return matchesTags && matchesGenre;
    });

    return filteredData;
};
