import React from 'react';
import { CASE_STUDIES_TAG } from 'views/case-studies/constants';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import CodeIcon from 'svgs/icons/ic-code.svg';
import MobileIcon from 'svgs/icons/ic-mobile.svg';
import MobileProgramming from 'svgs/icons/ic-mobile-programming.svg';
import Refresh from 'svgs/icons/ic-refresh.svg';

export const TILES_DATA = [
    {
        icon: <MobileIcon width={42} height={42} />,
        text: 'view-mobile-projects-tiles-section.react-native.title',
        description: 'view-mobile-projects-tiles-section.react-native.desc',
    },
    {
        icon: <CodeIcon width={42} height={42} />,
        text: 'view-mobile-projects-tiles-section.backend.title',
        description: 'view-mobile-projects-tiles-section.backend.desc',
    },
    {
        icon: <Refresh width={42} height={42} />,
        text: 'view-mobile-projects-tiles-section.maintenance.title',
        description: 'view-mobile-projects-tiles-section.maintenance.desc',
    },
    {
        icon: <MobileProgramming width={42} height={42} />,
        text: 'view-mobile-projects-tiles-section.native-mobile.title',
        description: 'view-mobile-projects-tiles-section.native-mobile.desc',
    },
];

export const useCaseStudiesData = () => {
    const images = useStaticQuery(graphql`
        {
            tunego: file(
                relativePath: {
                    eq: "case-studies/music-distribution-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
            cbre: file(
                relativePath: { eq: "case-studies/cbre-zone-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
            letempick: file(
                relativePath: { eq: "case-studies/letempick-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            title: 'case-studies-page.tunego',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.AUDIO_STREAMING,
            ],
            image: images.tunego.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.MUSIC_DISTRIBUTION_SOFTWARE}`,
        },
        {
            title: 'case-studies-page.cbreZone',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.cbre.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.COMMERCIAL_REAL_ESTATE_SOFTWARE_ZONE}`,
        },
        {
            title: 'case-studies-page.letempick',
            field: 'case-studies-page.field.videoAudioProcessing',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.letempick.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.SURVEY_SOFTWARE}`,
        },
    ];
};

const path = 'view-mobile-projects-solution-section';
export const PERKS = [
    {
        title: `${path}.experience.title`,
        text: `${path}.experience.desc`,
    },
    {
        title: `${path}.solutions.title`,
        text: `${path}.solutions.desc`,
        details: [
            `${path}.solutions.details.0`,
            `${path}.solutions.details.1`,
            `${path}.solutions.details.2`,
        ],
    },
    {
        title: `${path}.technologies.title`,
        text: `${path}.technologies.desc`,
        details: [
            `${path}.technologies.details.0`,
            `${path}.technologies.details.1`,
            `${path}.technologies.details.2`,
            `${path}.technologies.details.3`,
        ],
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.MOBILE],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'view-mobile-footer.button',
    },
    categoryBanner: {
        title: 'view-mobile-footer.title',
        description: 'view-mobile-footer.description',
        svg: JavascriptSvg,
    },
};
